export const parse = (content) => {
    let row = 0;
    for (let i = 0; i < content.sections.length; i += 1) {
        const section = content.sections[i];
        section.rows = [];
        for (let j = 0; j < section.span; j += 1) {
            // Row
            if (!section.rows[j]) {
                section.rows[j] = [];
            }

            for (let k = 0; k < content.columns; k += 1) {
                if (!section.rows[j][k]) {
                    section.rows[j][k] = [];
                }
                section.rows[j][k] = {
                    row,
                    col: k,
                    relativeRow: j,
                    code: `${row} j${j}k${k}`,
                };
            }
            row += 1;
        }
    }
    // console.log('see', content, content.columns);
    return content;
};

// Feed it lowercase keys for the definitions
export const replaceFromObject = (dictionary, content, handler = null) => {
    if (!content) {
        return '';
    }

    const defaultHandler = (key, d) => d[key];
    const replaceHandler = handler || defaultHandler;
    const patterns = [];
    const patternHash = {};
    let oldkey;
    let key;
    let index = 0;
    const output = [];

    const keys = Object.keys(dictionary);

    for (let i = 0; i < keys.length; i += 1) {
        key = keys[i];
        key = (oldkey = key).toLowerCase(); // eslint-disable-line
        dictionary[key] = dictionary[oldkey]; // eslint-disable-line
        patternHash[key] = index;
        // \b is used to mark boundaries "foo" doesn't match food
        patterns.push(`\\b(?:${key.replace(/([[^$.|?*+(){}])/g, '\\$1')})\\b`);
        index += 1;
    }

    let pattern = new RegExp(patterns.join('|'), 'gi');
    let lastIndex = 0;
    // console.log(content);
    // eslint-disable-next-line
    while ((key = pattern.exec(content))) {
        // Case-insensitivity
        key = key[0].toLowerCase();
        // Add to output buffer
        output.push(content.substring(lastIndex, pattern.lastIndex - key.length));
        // The next line is the actual replacement method
        output.push(replaceHandler(key, dictionary));
        // Update lastIndex variable
        lastIndex = pattern.lastIndex; // eslint-disable-line
        // Don't match again by removing the matched word, create new pattern
        patterns[patternHash[key]] = '^';
        // console.log(patterns, patternHash);
        pattern = new RegExp(patterns.join('|'), 'gi');
        // console.log(pattern, patterns[patternHash[key]]);
        // IMPORTANT: Update lastIndex property. Otherwise, enjoy an infinite loop
        pattern.lastIndex = lastIndex;
    }
    output.push(content.substring(lastIndex, content.length));
    return output.join('');
};

export const insertLinks = (items, html, handler = null) => {
    const obj = items || { nuchal: 'incroyable' };
    const result = replaceFromObject(obj, html, handler);
    return result;
};

export default {
    parse,
};
