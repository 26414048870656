export default {
    'down syndrome': 'Down syndrome',
    'patau syndrome': 'Patau syndrome',
    'edwards syndrome': 'Edwards syndrome',
    'neural tube defects': 'neural tube defects',
    dna: 'DNA',
    chromosomes: 'chromosomes',
    'klinefelter syndrome': 'Klinefelter syndrome',
    'turner syndrome': 'Turner syndrome',
    'triple x syndrome': 'triple X syndrome',
    'detection rate': 'detection rate',
    'false-positive rate': 'false-positive rate',
};
