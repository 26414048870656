// eslint-disable-next-line import/prefer-default-export
export const getLocaleFromLocation = (
    locales = ['fr', 'en'], // eslint-disable-line
    currentLocation = window.location, // eslint-disable-line
) => {
    const { pathname = '/' } = currentLocation; // eslint-disable-line
    // const localeRegEx = new RegExp(`^/(${locales.join('|')})(/.*)?$`, 'i');
    // const matches = pathname.match(localeRegEx);
    // return matches !== null ? matches[1] : locales[0];
    return 'en';
};

export const hexToRgbA = (hex, alpha = 1) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join('')}`;
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`; // eslint-disable-line
    }
    return `rgba(0,0,0,${alpha})`;
};

export const getNextColumn = (columns, index) => {
    const next = index + 1;
    if (next < columns.length) {
        return columns[next];
    }
    return columns[2];
};

export const getPreviousColumn = (columns, index) => {
    const prev = index - 1;
    if (prev >= 2) {
        return columns[prev];
    }
    return columns[columns.length - 1];
};
