export default {
    'down syndrome':
        'Down syndrome, or trisomy 21, is a condition that is caused by having three chromosomes 21 instead of two. It occurs in about 1 in 800 live births. Individuals with Down syndrome have delayed intellectual and physical development. The majority of children with Down syndrome learn to walk, talk, and eventually take care of their basic needs. However, it usually takes them longer to achieve this. Many children with Down syndrome attend regular schools. Individuals with Down syndrome can live into late adulthood, although they can have health issues such as heart defects. Most individuals and families living with Down syndrome report being happy and satisfied with their lives. Once they reach adulthood, many live independently or in group homes, although most need some type of supervision as adults.',
    'patau syndrome':
        'Patau syndrome, or trisomy 13, is caused by having three chromosomes 13 instead of two. It occurs in about 1 in 16 000 live births. Patau syndrome can lead to pregnancy loss or stillbirth. Individuals with Patau syndrome have severe intellectual disability as well as many physical malformations and birth defects, such as heart and kidney defects. About 5 to 10% of children who have Patau syndrome live beyond the age of 1. In extremely rare cases they can live up to the age of 5 or a little older. Those who survive with Patau syndrome may learn to smile, sit, and walk with assistance and they may build meaningful relationships with friends and family.',
    'edwards syndrome':
        'Edwards syndrome, or trisomy 18, is caused by having three chromosomes 18 instead of two. It occurs in about 1 in 5000 live births. Newborns with Edwards syndrome usually have a low birth weight because of slow growth in the mother’s womb. Individuals with Edwards syndrome have physical malformations and birth defects, most commonly heart and kidney defects. They also have a delayed physical and intellectual development and severe intellectual disability. About 5 to 10% of children with Edwards syndrome live beyond the age of 1. In very rare cases, individuals with less severe Edwards syndrome can live into early adulthood. Those who survive with Edwards syndrome may learn to smile, sit and stand and they may build meaningful relationships with friends and family.',
    'neural tube defects':
        'Neural tube defects (NTDs) are birth defects that develop in the first month of pregnancy and affect the brain and/or spinal cord of the fetus. They occur in about 1 in 750 newborns. Symptoms vary depending on the specific condition and range from no symptoms to stillbirth or death soon after birth. The most common NTDs are spina bifida and anencephaly. Spina bifida is a spinal cord defect. Depending on the type of spina bifida, people with this condition can have no symptoms, while others can have physical symptoms, such as paralysis, and/or intellectual disabilities. Anencephaly is a brain defect where some or all of the brain is missing. Anencephaly can cause stillbirth. Newborns with this condition generally stay unconscious and die soon after birth.  Other NTDs include encephalocele and iniencephaly.',
    dna:
        'DNA stands for “Deoxyribonucleic acid”. It is a molecule that contains instructions for the development and functioning of the body.',
    chromosomes:
        'Chromosomes contain DNA. DNA contains genes, which define characteristics such as sex, height, eye color, hair color, blood type and other physical features. Human cells usually contain 46 chromosomes; 23 from the mother and 23 from the father. Chromosome-pairs are numbered from 1 to 22. The 23rd pair is called the “sex chromosomes” (X and Y) because they define genetic sex. XX is defined as female and XY is defined as male. “Trisomy” means that there is an extra chromosome added to a typical pair. For example, trisomy 21 means having three instead of two copies of chromosome 21.',
    'klinefelter syndrome':
        'Klinefelter syndrome is a condition that affects only boys. It is caused by having an extra X chromosome (XXY instead of XY). It occurs in about 1 in 650 male newborns. There are generally no signs of the condition at birth, although some newborns may have undescended testicles (testicles that did not move down from the abdomen into the scrotum before birth). Most individuals with Klinefelter syndrome are not diagnosed in childhood. This condition is often diagnosed by chance in a fetus when prenatal diagnosis is done for other reasons, or when adults are tested for fertility issues. Individuals with Klinefelter syndrome can have learning difficulties, lower IQ, delayed motor development and delayed puberty. Most individuals with this condition have infertility issues. Individuals with Klinefelter syndrome have a normal lifespan and live as independent adults. However, they are at higher risk of developing various health conditions, such as type 2 diabetes and breast cancer.',
    'turner syndrome':
        'Turner syndrome is a condition that only affects females. It is caused by having one X chromosome instead of two (X instead of XX). It occurs in about 1 in 2500 female newborns. Most pregnancies with Turner syndrome lead to miscarriages early in the pregnancy. Most individuals with Turner syndrome are shorter than average, have delayed puberty and are infertile. Some have mild learning difficulties or behavioral problems. Some have heart and/or kidney defects. Individuals with Turner syndrome live as independent adults but have a slightly shorter lifespan.',
    'triple x syndrome':
        'Triple X syndrome is a condition that only affects females. It is caused by having three X chromosomes instead of two (XXX instead of XX). It occurs in about 1 in 1000 female newborns. Most individuals with Triple X syndrome are not diagnosed, as they generally do not have unusual physical features. However, some individuals with Triple X syndrome can be taller than average and have speech and language development delays, as well as learning difficulties. Individuals with Triple X syndrome are generally fertile. They have a normal lifespan and live as independent adults.',
    'detection rate':
        'An 83% detection rate means that 83 out of 100 fetuses with Down syndrome will be correctly given a “high probability” (‘high risk’) result. This means that 17 out of 100 fetuses with Down syndrome will not be identified by this screening.',
    'false-positive rate':
        'A 5% false-positive rate means that 5 out of 100 fetuses without Down syndrome will be incorrectly given a “positive screen” even if they don’t have the condition.',
};
