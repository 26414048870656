/**
 * Constants
 */

export const SET_SIZE = 'layout@SET_SIZE';
export const SET_FONTS_LOADED = 'layout@SET_FONTS_LOADED';
export const SET_MODAL = 'layout@SET_MODAL';
export const SET_COLUMN_VISIBILITY = 'layout@SET_COLUMN_VISIBILITY';
export const SET_ZOOM_INDEX = 'layout@SET_ZOOM_INDEX';
export const SET_COLLAPSED = 'layout@SET_COLLAPSED';
export const SET_VISIBLE_COLUMN = 'layout@SET_VISIBLE_COLUMN';

/**
 * Actions creator
 */

export const setSize = payload => ({
    type: SET_SIZE,
    payload,
});

export const setFontsLoaded = payload => ({
    type: SET_FONTS_LOADED,
    payload,
});

export const setModal = payload => ({
    type: SET_MODAL,
    payload,
});

export const setColumnVisibility = payload => ({
    type: SET_COLUMN_VISIBILITY,
    payload,
});

export const setZoomIndex = payload => ({
    type: SET_ZOOM_INDEX,
    payload,
});

export const setCollapsed = payload => ({
    type: SET_COLLAPSED,
    payload,
});

export const setVisibleColumn = payload => ({
    type: SET_VISIBLE_COLUMN,
    payload,
});
