import {
    SET_SIZE,
    SET_FONTS_LOADED,
    SET_MODAL,
    SET_COLUMN_VISIBILITY,
    SET_ZOOM_INDEX,
    SET_COLLAPSED,
    SET_VISIBLE_COLUMN,
} from '../actions/LayoutActions';

const initialState = {
    size: {
        width:
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height:
            window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight,
    },
    fontsLoaded: false,
    modal: null,
    columns: [],
    zoomIndex: { row: null, col: null },
    visibleColumn: 3,
    collapsed: window.innerWidth < 960,
};

const LayoutReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    switch (action.type) {
    case SET_SIZE:
        return {
            ...state,
            size: {
                ...action.payload,
            },
        };
    case SET_FONTS_LOADED:
        return {
            ...state,
            fontsLoaded: action.payload,
        };
    case SET_MODAL:
        return {
            ...state,
            modal: action.payload,
        };
    case SET_ZOOM_INDEX:
        return {
            ...state,
            zoomIndex: action.payload,
        };
    case SET_VISIBLE_COLUMN:
        return {
            ...state,
            visibleColumn: action.payload,
        };
    case SET_COLLAPSED:
        return {
            ...state,
            collapsed: action.payload,
        };
    case SET_COLUMN_VISIBILITY:
            const idx = state.columns.indexOf(action.payload); // eslint-disable-line
            const columns = state.columns.concat([]); // eslint-disable-line
        if (idx === -1) {
            columns.push(action.payload);
        } else {
            columns.splice(idx, 1);
        }
        return {
            ...state,
            columns,
        };
    default:
        return state;
    }
};

export default LayoutReducer;
