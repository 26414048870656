import Baby1 from '../img/babies/baby-1.png';
import Baby2 from '../img/babies/baby-2.png';
import Baby3 from '../img/babies/baby-3.png';
import Baby4 from '../img/babies/baby-4.png';
import Baby5 from '../img/babies/baby-5.png';
import Baby6 from '../img/babies/baby-6.png';
import Baby7 from '../img/babies/baby-7.png';
import Baby8 from '../img/babies/baby-8.png';
import Baby9 from '../img/babies/baby-9.png';

import Fruit1 from '../img/fruit-thumbs/fruit-1.png';
import Fruit2 from '../img/fruit-thumbs/fruit-2.png';
import Fruit3 from '../img/fruit-thumbs/fruit-3.png';
import Fruit4 from '../img/fruit-thumbs/fruit-4.png';
import Fruit5 from '../img/fruit-thumbs/fruit-5.png';
import Fruit6 from '../img/fruit-thumbs/fruit-6.png';
import Fruit7 from '../img/fruit-thumbs/fruit-7.png';
import Fruit8 from '../img/fruit-thumbs/fruit-8.png';
import Fruit9 from '../img/fruit-thumbs/fruit-9.png';

import BriefIntro from '../html/en/intro-brief.html';
import MoreIntro from '../html/en/intro-more.html';

// Top groups

import BriefPrenatalScreening from '../html/en/section-screening-brief.html';
import MorePrenatalScreening from '../html/en/section-screening-more.html';

import BriefPrenatalDiagnosis from '../html/en/section-diagnosis-brief.html';
import MorePrenatalDiagnosis from '../html/en/section-diagnosis-more.html';

import BriefDecisions from '../html/en/section-decisions-brief.html';
import MoreDecisions from '../html/en/section-decisions-more.html';

// Columns

// import BriefColUltrasound from '../html/en/column-ultrasound-brief.html';

import BriefColRaise from '../html/en/column-raise-the-child-brief.html';
import MoreColRaise from '../html/en/column-raise-the-child-more.html';
import MoreColRaise2 from '../html/en/column-raise-the-child-more-2.html';

import BriefColAdoption from '../html/en/column-adoption-brief.html';
import MoreColAdoption from '../html/en/column-adoption-more.html';

import BriefColEnding from '../html/en/column-ending-brief.html';
import MoreColEnding from '../html/en/column-ending-more.html';

// Lines

import BriefCfDNA from '../html/en/1-cfdna-brief.html';
import MoreCfDNA from '../html/en/1-cfdna-more.html';

import BriefSerum from '../html/en/2-serum-brief.html';
import MoreSerum from '../html/en/2-serum-more.html';
import BriefSerum2T from '../html/en/2-serum-2t-brief.html';

import BriefDating from '../html/en/3-dating-ultrasound-brief.html';
// import MoreDating from '../html/en/3-dating-more.html';

import BriefNuchal from '../html/en/4-nuchal-brief.html';
import MoreNuchal from '../html/en/4-nuchal-more.html';
// import MoreNuchal from '../html/en/4-nuchal-more.html';

import Brief2Ultra from '../html/en/5-2ultra-brief.html';
import More2Ultra from '../html/en/5-2ultra-more.html';

import Brief3Ultra from '../html/en/6-3ultra-brief.html';
import More3Ultra from '../html/en/6-3ultra-more.html';

import BriefCVS from '../html/en/7-cvs-brief.html';
import MoreCVS from '../html/en/7-cvs-more.html';

import BriefAmnio from '../html/en/8-amnio-brief.html';
import MoreAmnio from '../html/en/8-amnio-more.html';

import BriefDetailed from '../html/en/9-detailed-brief.html';
// import MoreDetailed from '../html/en/9-detailed-more.html';

import BriefMorpho from '../html/en/10-morpho-brief.html';
// import MoreMorpho from '../html/en/10-morpho-more.html';

import BriefFetal from '../html/en/11-fetal-brief.html';
// import MoreMorpho from '../html/en/10-morpho-more.html';

import BriefLaborRaise from '../html/en/12-labor-brief.html';
// import MoreMorpho from '../html/en/10-morpho-more.html';

import BriefPharma from '../html/en/13-pharma-brief.html';
import MorePharma from '../html/en/13-pharma-more.html';

// import BriefVacuum from '../html/en/10-vacuum-brief.html';
// import MoreVacuum from '../html/en/10-vacuum-more.html';

import BriefVacuumEvacuation from '../html/en/14-vacuum-brief.html';
import MoreVacuumEvacuation from '../html/en/14-vacuum-more.html';

// import BriefEvacuation from '../html/en/15-evacuation-brief.html';
// import MoreEvacuation from '../html/en/15-evacuation-more.html';

import BriefExtraction from '../html/en/16-extraction-brief.html';
import MoreExtraction from '../html/en/16-extraction-more.html';
import MoreExtraction2 from '../html/en/16-extraction-more-2.html';

import BriefLabor from '../html/en/17-labor-brief.html';
import MoreLabor from '../html/en/17-labor-more.html';
import MoreLabor2 from '../html/en/17-labor-more-2.html';

import definitions from '../html/en/definitions';
import aliases from '../html/en/aliases';

const en = () => ({
    title: 'Pregnancy timeline',
    weeks: 42,
    columns: 12,
    definitions,
    aliases,
    intro: {
        html: BriefIntro,
        more: MoreIntro,
    },
    header: [
        {
            title: null,
            itemClass: 'first',
            columns: [{ index: 0, label: 'Weeks' }],
        },
        {
            title: null,
            itemClass: 'second',
            columns: [{ index: 1, label: 'Pregnancy Milestones' }],
        },
        {
            title: null,
            columns: [
                { index: 2, label: 'Your fetus looks like' },
                { index: 3, label: 'Your fetus size' },
                // { index: 4, label: 'Fetus Milestones' },
            ],
        },
        {
            title: 'Prenatal screening',
            columns: [
                { index: 4, label: 'cfDNA screening' },
                { index: 5, label: 'Serum screening' },
                {
                    index: 6,
                    label: 'Ultrasounds',
                    // html: BriefColUltrasound,
                },
            ],
            html: BriefPrenatalScreening,
            more: MorePrenatalScreening,
            color: '#8cc63f',
        },
        {
            title: 'Prenatal diagnosis',
            groupClass: 'light',
            columns: [
                { index: 7, label: 'CVS' },
                { index: 8, label: 'Amniocentesis' },
            ],
            html: BriefPrenatalDiagnosis,
            more: MorePrenatalDiagnosis,
            color: '#fbb03b',
        },
        {
            title: 'Pregnancy decisions', // after a positive diagnosis
            columns: [
                {
                    index: 9,
                    label: 'Raise the child<br/>with the condition',
                    html: BriefColRaise,
                    more: MoreColRaise,
                    muchmore: MoreColRaise2,
                    muchmoreLabel: 'Learn more : Medical and emotional preparation',
                    color: '#3599d4',
                },
                {
                    index: 10,
                    label: 'Adoption',
                    html: BriefColAdoption,
                    more: MoreColAdoption,
                    color: '#78bae3',
                },
                {
                    index: 11,
                    label: 'Terminate<br/>the pregnancy',
                    html: BriefColEnding,
                    more: MoreColEnding,
                    color: '#78bae3',
                },
            ],
            html: BriefDecisions,
            more: MoreDecisions,
            color: '#3599d4',
        },
    ],
    sections: [
        {
            lines: ['Last period'],
            span: 1,
        },
        {
            lines: ['Ovulation (~day14)'],
            span: 2,
        },
        {
            lines: ['Missed period'],
            span: 1,
        },
        {
            lines: ['Pregnancy is detectable', 'Morning sickness', 'Decreased energy', 'Cravings'],
            span: 7,
        },
        {
            lines: [
                'End of first trimester',
                'Miscarriage risk reduced',
                'Pregnancy may start to show',
                'Increased energy',
                'Nausea usually subsides',
            ],
            span: 6,
        },
        {
            lines: ['Quickening', '(Pregnant woman may feel fetal movement)'],
            span: 5,
        },
        {
            lines: ['Fetus may survive if born and provided with life sustaining care'],
            span: 4,
        },
        {
            lines: ['End of second trimester'],
            span: 1,
        },
        {
            lines: [
                'Increased pregnancy symptoms: fatigue, swelling, shortness of breath, heartburn, joint pain, back pain…',
            ],
            span: 9,
        },
        {
            lines: ['Pregnancy to term', '(birth would no longer be considered premature)'],
            span: 3,
        },
        {
            lines: ['Expected due date'],
            span: 1,
        },
        {
            lines: ['Fetus monitoring'],
            span: 1,
        },
        {
            lines: ['Labor induction'],
            span: 1,
        },
    ],
    items: [
        {
            type: 'image',
            row: 4,
            col: 2,
            message: null,
            image: Baby1,
        },
        {
            type: 'image',
            row: 8,
            col: 2,
            message: null,
            image: Baby2,
        },
        {
            type: 'image',
            row: 12,
            col: 2,
            message: null,
            image: Baby3,
        },
        {
            type: 'image',
            row: 16,
            col: 2,
            message: null,
            image: Baby4,
        },
        {
            type: 'image',
            row: 20,
            col: 2,
            message: null,
            image: Baby5,
        },
        {
            type: 'image',
            row: 24,
            col: 2,
            message: null,
            image: Baby6,
        },
        {
            type: 'image',
            row: 28,
            col: 2,
            message: null,
            image: Baby7,
        },
        {
            type: 'image',
            row: 32,
            col: 2,
            message: null,
            image: Baby8,
        },
        {
            type: 'image',
            row: 36,
            col: 2,
            message: null,
            image: Baby9,
        },
        {
            type: 'image',
            row: 4,
            col: 3,
            message: 'Coffee bean',
            image: Fruit1,
        },
        {
            type: 'image',
            row: 8,
            col: 3,
            message: 'Grape',
            image: Fruit2,
        },
        {
            type: 'image',
            row: 12,
            col: 3,
            message: 'Lemon',
            image: Fruit3,
        },
        {
            type: 'image',
            row: 16,
            col: 3,
            message: 'Bell pepper',
            image: Fruit4,
        },
        {
            type: 'image',
            row: 20,
            col: 3,
            message: 'Grapefruit',
            image: Fruit5,
        },
        {
            type: 'image',
            row: 24,
            col: 3,
            message: 'Coconut',
            image: Fruit6,
        },
        {
            type: 'image',
            row: 28,
            col: 3,
            message: 'Cauliflower',
            image: Fruit7,
        },
        {
            type: 'image',
            row: 32,
            col: 3,
            message: 'Small watermelon',
            image: Fruit8,
        },
        {
            type: 'image',
            row: 36,
            col: 3,
            message: 'Small pumpkin',
            image: Fruit9,
        },
        {
            type: 'line',
            row: 8,
            col: 4,
            label: 'cfDNA screening',
            html: BriefCfDNA,
            more: MoreCfDNA,
            span: 33,
            color: '#8cc63f',
        },
        {
            type: 'line',
            row: 9,
            col: 5,
            label: '1st trimester biochemical markers',
            html: BriefSerum,
            more: MoreSerum,
            span: 3,
            color: '#009245',
        },
        {
            type: 'line',
            row: 13,
            col: 5,
            label: '2nd trimester biochemical markers',
            html: BriefSerum2T,
            more: MoreSerum,
            span: 2,
            color: '#009245',
        },
        {
            type: 'line',
            row: 6,
            col: 6,
            label: 'Dating ultrasound',
            html: BriefDating,
            span: 3,
            color: '#00cc99',
        },
        {
            type: 'line',
            row: 10,
            col: 6,
            label: 'Nuchal scan',
            html: BriefNuchal,
            more: MoreNuchal,
            span: 2,
            color: '#00cc99',
        },
        {
            type: 'line',
            row: 17,
            col: 6,
            label: 'Second trimester ultrasound',
            html: Brief2Ultra,
            more: More2Ultra,
            span: 3,
            color: '#00cc99',
        },
        {
            type: 'line',
            row: 29,
            col: 6,
            label: '3rd trimester ultrasound (if recommended)',
            html: Brief3Ultra,
            more: More3Ultra,
            span: 2,
            color: '#00cc99',
        },
        {
            type: 'line',
            row: 9,
            col: 7,
            label: 'CVS',
            html: BriefCVS,
            more: MoreCVS,
            span: 3,
            color: '#fbb03b',
        },
        {
            type: 'line',
            row: 14,
            col: 8,
            label: 'Amniocentesis',
            html: BriefAmnio,
            more: MoreAmnio,
            span: 27,
            color: '#fc9917',
        },
        {
            type: 'line',
            row: 17,
            col: 9,
            label: 'Ultrasound or echocardiogram or endovaginal scan',
            html: BriefDetailed,
            span: 2,
            color: '#3599d4',
        },
        {
            type: 'line',
            row: 27,
            col: 9,
            label: 'Repeat detailed morphology ultrasound',
            html: BriefMorpho,
            span: 2,
            color: '#3599d4',
        },
        {
            type: 'line',
            row: 33,
            col: 9,
            label: 'Fetal monitoring by non-stress test or repeat detailed mophology ultrasound',
            html: BriefFetal,
            span: 4,
            color: '#3599d4',
        },
        {
            type: 'line',
            row: 38,
            col: 9,
            label: 'Birth by labor induction',
            html: BriefLaborRaise,
            span: 1,
            color: '#3599d4',
        },
        {
            type: 'line',
            row: 4,
            col: 11,
            label: 'Pharmacological pregnancy termination',
            html: BriefPharma,
            more: MorePharma,
            moreLabel: 'Learn more (sensitive content)',
            span: 4,
            color: '#78bae3',
        },
        {
            type: 'line',
            row: 9,
            col: 11,
            label: 'Vacuum aspiration and curettage or dilation with evacuation',
            html: BriefVacuumEvacuation,
            more: MoreVacuumEvacuation,
            moreLabel: 'Learn more (sensitive content)',
            span: 3,
            color: '#78bae3',
        },
        {
            type: 'line',
            row: 13,
            col: 11,
            label: 'Dilation with extraction',
            html: BriefExtraction,
            more: MoreExtraction,
            muchmore: MoreExtraction2,
            moreLabel: 'Learn more (sensitive content)',
            muchmoreLabel: 'Learn more (sensitive content)',
            span: 9,
            color: '#78bae3',
        },
        {
            type: 'line',
            row: 23,
            col: 11,
            label: 'Labor induction',
            html: BriefLabor,
            more: MoreLabor,
            muchmore: MoreLabor2,
            moreLabel: 'Learn more (sensitive content)',
            muchmoreLabel: 'Learn more (sensitive content)',
            span: 18,
            color: '#78bae3',
        },
    ],
});

export default en;
