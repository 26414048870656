import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WebFont from 'webfontloader';

import {
    setSize as setSizeAction,
    setCollapsed as setCollapsedAction,
    setFontsLoaded as setFontsLoadedActions,
} from '../../actions/LayoutActions';

import styles from '../../styles/layouts/main.scss';

const propTypes = {
    fontsLoaded: PropTypes.bool.isRequired,
    setFontsLoaded: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    setSize: PropTypes.func.isRequired,
    setCollapsed: PropTypes.func.isRequired,
    isPrerender: PropTypes.bool,
    fonts: PropTypes.shape({
        google: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        custom: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    }),
};

const defaultProps = {
    isPrerender: false,
    fonts: {
        google: {
            families: [
                'Archivo+Black',
                'Asap+Condensed:400,500,600,700',
                'Source+Serif+Pro:400,600,700',
            ],
        },
        custom: {},
    },
};

class MainLayout extends Component {
    constructor(props) {
        super(props);
        this.onResize = this.onResize.bind(this);
        this.onFontsActive = this.onFontsActive.bind(this);
    }

    componentDidMount() {
        const { fonts } = this.props;
        WebFont.load({
            ...fonts,
            active: this.onFontsActive,
        });
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onFontsActive() {
        const { setFontsLoaded } = this.props;
        setFontsLoaded(true);
    }

    onResize() {
        const { setSize, setCollapsed } = this.props;
        // eslint-disable-next-line max-len
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const height = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
        setSize({
            width,
            height,
        });
        setCollapsed(width < 960);
    }

    render() {
        const { children, fontsLoaded, isPrerender } = this.props;
        const innerStyle = {
            opacity: fontsLoaded || isPrerender ? 1 : 0,
        };
        return (
            <div className={styles.container}>
                <div className={styles.inner} style={innerStyle}>
                    <div className={styles.content}>{children}</div>
                </div>
            </div>
        );
    }
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const mapStateToProps = ({ content, layout, site }) => ({
    content,
    size: layout.size,
    fontsLoaded: layout.fontsLoaded,
    isPrerender: site.isPrerender || false,
});

const mapDispatchToProps = dispatch => ({
    setSize: size => dispatch(setSizeAction(size)),
    setCollapsed: collapsed => dispatch(setCollapsedAction(collapsed)),
    setFontsLoaded: loaded => dispatch(setFontsLoadedActions(loaded)),
});

const WithStateContainer = connect(mapStateToProps, mapDispatchToProps)(MainLayout);

export default WithStateContainer;
