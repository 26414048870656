import { getLocaleFromLocation } from '../lib/utils';
import { parse } from '../lib/content';
import fr from '../lang/fr';
import en from '../lang/en';

const content = {
    fr: fr(),
    en: en(),
};

export default {
    locale: getLocaleFromLocation(),
    messages: {
        'meta.title': 'Pégase',
        'meta.description': 'Pégase description',
        'meta.weeks': 'Weeks',
        'content.x': 'x',
    },
    routes: {
        home: '/',
    },
    content: parse(content[getLocaleFromLocation()]),
};
