import PropTypes from 'prop-types';

/**
 * Core
 */
export const urlGenerator = PropTypes.shape({
    route: PropTypes.func.isRequired,
});

export const intl = PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
});

export const message = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
});

export const label = PropTypes.oneOfType([message, PropTypes.node]);

/**
 * Site
 */

export const headerGroup = PropTypes.shape({
    title: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object),
});

export const section = PropTypes.shape({
    lines: PropTypes.array.isRequired,
    span: PropTypes.number.isRequired,
    columns: PropTypes.object,
});

export const content = PropTypes.shape({
    title: PropTypes.string,
    weeks: PropTypes.number,
    header: PropTypes.arrayOf(headerGroup),
    sections: PropTypes.arrayOf(section),
});

export const modal = PropTypes.shape({
    row: PropTypes.number,
    col: PropTypes.number,
});

export const size = PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
});
